<template> 
    <v-layout justify-center class="pt-9" fill-height>  
        <mbs-page-spinner v-if="!DayShift" />   
        <mbs-epson-display-temp1 v-else
            :show="true"
            :loading="inputLoading"
            :print_request="DAY_SHIFT_PRINT_REQUEST"/> 
        
    </v-layout> 
</template>

<script>
    import DATA from "../../../plugins/DATA"
    import {mapState } from "vuex"
    let PAGE_NAME  = 'DAY-SHIFT-TEMPLATE-1'
    export default {
        props:['data','report_type','sales_type','closing_cash'], 
        data(){
            return{
                show:true,
                DATA:DATA,
                printSVG:null,
                inputLoading:true,
                DAY_SHIFT_PRINT_REQUEST:null,
            } 
        }, 
        mounted(){ 
            this.EPSON_DAY_SHIFT_REQUEST({
                report_type:this.report_type,
                day_shift:this.DayShift,
            }) 
        },
        computed:{
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                Payments: state=> state.items[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
                JoinedPayments: state=> state.join[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],  
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],  
                LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
            }), 
            DataInvoicePayments(){
                let Payments = this.Payments
                let JoinedPayments = this.JoinedPayments 
                return JoinedPayments?JoinedPayments:Payments
            },
            DataSaleOrders(){
                let SaleOrders = this.SaleOrders
                let JoinedSaleOrders = this.JoinedSaleOrders 
                return JoinedSaleOrders?JoinedSaleOrders:SaleOrders
            },
            AllLocalSaleOrdersData(){  
                const LocalSaleOrders = this.LocalSaleOrders
                const JoinedLocalSaleOrders = this.JoinedLocalSaleOrders
                let items = JoinedLocalSaleOrders?JoinedLocalSaleOrders:LocalSaleOrders  
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",true)) 
                return list
            }, 
            BothSaleOrders(){
                let orders = this.DataSaleOrders
                let localOrders = this.AllLocalSaleOrdersData
                if(!orders && !localOrders){return null}
                if(orders && !localOrders){return orders}
                if(!orders && localOrders){return localOrders}

                let both = [...orders]
                localOrders.forEach(element => {
                    let item = orders.find(item=>{
                        return item.key == element.key
                    })
                    if (!item) {
                        both.push(element)
                    } 
                });
                let list = [...both]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                return list
            },
            ShowShortagesSettings(){
                let settings = this.CompanySettings
                let show_shortages = settings?settings.show_shortages:false 
                return show_shortages
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            DayShift(){ 
                let data = this.data 
                return  data
            },
            Company(){ 
                let item = this.DayShift
                return item?item.company:null
            }, 
            Cashups(){ 
                let item = this.DayShift 
                return item?item.cashups:null
            }, 
            CashupSalesOtherOptionPayments(){ 
                let cashups = this.Cashups 
                if(!cashups){return null}
                let options = []
                for (const cashup_key in cashups) {
                    const element = cashups[cashup_key]; 
                    let other_option_payments = element.sales_other_option_payments
                    if (other_option_payments) {
                        for (const key in other_option_payments) {
                            const other_option = other_option_payments[key]; 
                            let index = options.findIndex(option=>{
                                return option.payment_option_code  == key
                            }) 
                            if (index == -1) {
                                let payment_method = this.$store.getters.getPaymentMethodByCode(key)
                                options.push({
                                    ...other_option,
                                    payment_method:payment_method,
                                    payment_method_name:payment_method?payment_method.name:"---", 
                                })
                            } else {
                                options[index].amount = options[index].amount+this.toNumber(other_option.amount)
                            }  
                        }
                    }  
                } 
                return this.MBS.actions.SIZE(options)>0?options:null
            }, 
            Joined_Cashups(){ 
                let item = this.DayShift  
                let cashups = item?item.joined_cashups:null   
                return cashups
            }, 
            TotalCashups(){ 
                let items = this.Joined_Cashups 
                let total = this.MBS.actions.CALCULATE_TOTAL_CASHUPS(items)  
                return total
            }, 
            ClosedCashups(){ 
                let items = this.Joined_Cashups 
                if(!items){return null} 
                let filtered = items.filter(cashup=>{
                    return cashup.closed
                })
                return filtered
            }, 
            UnClosedCashups(){ 
                let items = this.Joined_Cashups 
                if(!items){return null} 
                let filtered = items.filter(cashup=>{
                    return !cashup.closed
                })
                return filtered
            }, 
            
            AllShortages(){ 
                let items = this.Joined_Cashups 
                if(!items){return null}
                let shortage = 0
                items.forEach(cashup => {
                    shortage = shortage + this.SHORTAGE(cashup)
                });
                return shortage
            }, 
            AllOverages(){ 
                let items = this.Joined_Cashups 
                if(!items){return null}
                let overage = 0
                items.forEach(cashup => {
                    overage = overage + this.OVERAGE(cashup)
                });
                return overage
            }, 

            
             
            DayShiftSaleOrders(){  
                let sales = this.BothSaleOrders
                let DayShift = this.DayShift
                let day_shift_key = DayShift?DayShift.key:null 
                if(!day_shift_key||!sales){return null}
                let filtered_items = sales.filter(item=>{
                    return item.day_shift_key == day_shift_key
                })   
                return filtered_items  
            }, 
            DayShiftSaleOrdersItems(){
                let sales = this.DayShiftSaleOrders  
                let items = this.ORDER_ITEMS(sales)  
                return items
            },
            RetailDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.RETAIL_ITEMS(items)
                return retail_items 
            },
            WholesaleDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.WHOLESALE_ITEMS(items)
                return retail_items 
            },
            CashDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.CASH_ITEMS(items)
                return retail_items 
            },
            OtherOptionsDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.OTHER_OPTIONS_ITEMS(items) 
                return retail_items 
            },
            OnAccountDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.ON_ACCOUNT_ITEMS(items)
                return retail_items 
            },
            
            CashupOrders(){
                let orders = this.DayShiftSaleOrders
                return cashup_key =>{
                    if(!orders){return null}
                    return orders.filter(order=>{
                        return order.cashup_key == cashup_key
                    })
                }
            },
            CashupOrdersItems(){
                return (cashup_key)=>{
                    let sales = this.CashupOrders(cashup_key)
                    let items = this.ORDER_ITEMS(sales) 
                    return items 
                }
            },
            RetailCashupItems(){ 
                return (cashup_key)=>{
                    let items = this.CashupOrdersItems(cashup_key)
                    let retail_items = this.RETAIL_ITEMS(items)
                    return retail_items  
                }
            },
            WholesaleCashupItems(){ 
                return (cashup_key)=>{
                    let items = this.CashupOrdersItems(cashup_key)
                    let retail_items = this.WHOLESALE_ITEMS(items)
                    return retail_items  
                }
            },
            CashCashupItems(){ 
                return (cashup_key)=>{
                    let items = this.CashupOrdersItems(cashup_key)
                    let retail_items = this.CASH_ITEMS(items) 
                    return retail_items  
                }
            },
            OtherOptionsCashupItems(){ 
                return (cashup_key)=>{
                    let items = this.CashupOrdersItems(cashup_key)
                    let retail_items = this.OTHER_OPTIONS_ITEMS(items) 
                    return retail_items  
                }
            },
            OnAccountCashupItems(){ 
                return (cashup_key)=>{
                    let items = this.CashupOrdersItems(cashup_key)
                    let retail_items = this.ON_ACCOUNT_ITEMS(items)
                    return retail_items  
                }
            }, 
            TotalClosingCash(){
                let cashups = this.Joined_Cashups
                if(!cashups){return null}
                let total = 0
                cashups.forEach(element => {
                    total = total+this.toNumber(element.closing_cash)
                });
                return total
            }, 
            ThisInvoicePayments(){
                let payments = this.DataInvoicePayments
                let DayShift = this.DayShift
                let day_shift_key = DayShift?DayShift.key:null
                if(!payments||!day_shift_key){return null}
                let filled_items = payments.filter(item=>{
                    return item.day_shift_key == day_shift_key
                }) 
                return filled_items
            },
            CashInvoicePayments(){
                let payments = this.ThisInvoicePayments 
                if(!payments){return null}
                let filled_items = payments.filter(item=>{
                    return !item.payment_option_code
                }) 
                return filled_items
            },
            OtherInvoicePayments(){  
                let payments = this.ThisInvoicePayments 
                if(!payments){return null}
                let filled_items = payments.filter(item=>{
                    return item.payment_option_code
                })  
                return filled_items
            },
            TotalInvoicePayments(){
                try { 
                    let items = this.ThisInvoicePayments
                    let total_amount = 0 
                    if (!items) {return 0}
                    items.forEach(item => {
                        let amount = this.toNumber(item.amount) 
                        total_amount = total_amount + amount
                    });  
                    return total_amount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalInvoicePayments',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },  
            SalesOtherOptionPayments(){
                //Cashup Data
                let TotalCashups = this.TotalCashups
                let sales_other_option_payments = TotalCashups?TotalCashups.sales_other_option_payments:null
                let sales_total_other_option_payments = TotalCashups?TotalCashups.sales_total_other_option_payments:0 
                 
                //Other Data
                let items = this.OtherOptionsDayShiftItems   
                let total_item_options = 0
                let options = []
                if (items) {
                    items.forEach(element => {
                        let payment_option_code = element.payment_option_code
                        let sold_price = this.toNumber(element.sold_price)
                        let quantity = this.toNumber(element.quantity)
                        let discount = this.toNumber(element.discount)
                        let price = (sold_price-discount) *quantity

                        let index = options.findIndex(option=>{
                            return option.payment_option_code  == payment_option_code
                        }) 
                        if (payment_option_code) {
                            if (index == -1) {
                                let payment_method = this.$store.getters.getPaymentMethodByCode(payment_option_code)
                                options.push({
                                    payment_method:payment_method,
                                    payment_method_name:payment_method?payment_method.name:"---",
                                    payment_option_code:payment_option_code,
                                    amount:price
                                })
                            } else {
                                options[index].amount = options[index].amount+price
                            }
                            total_item_options = total_item_options+price
                        } 
                    });   
                }
                return sales_total_other_option_payments>total_item_options?{
                    options:sales_other_option_payments,
                    total:sales_total_other_option_payments,
                }:{
                    options:options,
                    total:total_item_options
                }
            },  
            InvoiceOtherOptionPayments(){
                //From Cashup Data
                let TotalCashups = this.TotalCashups
                let invoices_other_option_payments = TotalCashups?TotalCashups.invoices_other_option_payments:null
                let invoices_total_other_option_payments = TotalCashups?TotalCashups.invoices_total_other_option_payments:0 
                 
                //Offline
                let items = this.OtherInvoicePayments   
                let total_item_options = 0
                let options = []
                if (items) {
                    items.forEach(element => {
                        let payment_option_code = element.payment_option_code
                        let index = options.findIndex(option=>{
                            return option.payment_option_code  == payment_option_code
                        }) 
                        if (payment_option_code) {
                            if (index == -1) {
                                let payment_method = this.$store.getters.getPaymentMethodByCode(payment_option_code)
                                options.push({
                                    payment_method:payment_method,
                                    payment_method_name:payment_method?payment_method.name:"---",
                                    payment_option_code:payment_option_code,
                                    amount:this.toNumber(element.amount)
                                })
                            } else {
                                options[index].amount = options[index].amount+this.toNumber(element.amount) 
                            }
                            total_item_options = total_item_options+this.toNumber(element.amount)
                        } 
                    });   
                }
                return invoices_total_other_option_payments>total_item_options?{
                    options:invoices_other_option_payments,
                    total:invoices_total_other_option_payments,
                }:{
                    options:options,
                    total:total_item_options
                }
            },  
            AllOtherOptionPayments(){
                //From Cashup Data
                let SalesOtherOptionPayments = this.SalesOtherOptionPayments
                let InvoiceOtherOptionPayments = this.InvoiceOtherOptionPayments
                let options = [] 

                if (SalesOtherOptionPayments.options) {
                    options = [...SalesOtherOptionPayments.options]
                }if (InvoiceOtherOptionPayments.options) {
                    InvoiceOtherOptionPayments.options.forEach(element => {
                        let exist = options.findIndex(item=>item.payment_option_code==element.payment_option_code)
                        if (exist != -1) {
                            options[exist].amount = options[exist].amount+element.amount
                        } else {
                            options.push(element) 
                        } 
                    }); 
                } 

                console.log(options,'options....');
                return {
                    options:options,
                    total:this.toNumber(SalesOtherOptionPayments.total)+this.toNumber(InvoiceOtherOptionPayments.total)
                }
            },  
        },
        methods:{
            async EPSON_DAY_SHIFT_REQUEST(payload){ 
                try {   
                    let day_shift = this.DayShift 
                    this.inputLoading = true
                    let print_order = await this.MBS.actions.EPSON_DAY_SHIFT_REQUEST(payload) 
                    this.DAY_SHIFT_PRINT_REQUEST = print_order?print_order.data:null
                    this.inputLoading = false 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EPSON_DAY_SHIFT_REQUEST',
                        page:PAGE_NAME, 
                    }) 
                    return 
                }   
            },  
            TOTAL_AMOUNT(items){
                try {  
                    let total_amount = 0 
                    if (!items) {return 0}
                    items.forEach(item => {
                        let amount = this.toNumber(item.amount) 
                        total_amount = total_amount + amount
                    });  
                    return total_amount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TOTAL_AMOUNT',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },  
              
            SHORTAGE(cashup){
                try { 
                    if(!cashup){return 0} 
                    if (!cashup.closed) {
                        return 0
                    }
                    let sales_cash_payments = this.toNumber(cashup.sales_cash_payments)
                    let sales_on_account = this.toNumber(cashup.sales_on_account)
                    let invoices_cash_payments = this.toNumber(cashup.invoices_cash_payments)
                    let closing_cash = this.toNumber(cashup.closing_cash)
                    let opening_cash = this.toNumber(cashup.opening_cash)
                    let total_cash = sales_cash_payments+opening_cash+invoices_cash_payments
                    let def =  closing_cash-total_cash
 
                    if (total_cash>closing_cash) {
                       return def*-1
                    }
                    return 0
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SHORTAGE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            OVERAGE(cashup){
                try { 
                    if(!cashup){return 0}
                    if (!cashup.closed) {
                        return 0
                    }
                    let sales_cash_payments = this.toNumber(cashup.sales_cash_payments)
                    let sales_on_account = this.toNumber(cashup.sales_on_account)
                    let invoices_cash_payments = this.toNumber(cashup.invoices_cash_payments)
                    let closing_cash = this.toNumber(cashup.closing_cash)
                    let opening_cash = this.toNumber(cashup.opening_cash)
                    let total_cash = sales_cash_payments+opening_cash+invoices_cash_payments
                    let def =  closing_cash-total_cash
                    if (total_cash<closing_cash) {
                       return def 
                    }
                    return 0
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'OVERAGE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },  
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let sold_price = this.toNumber(item.sold_price)
                    let discount = this.toNumber(item.discount)
                    let quantity = this.toNumber(item.quantity)
                    return quantity*(sold_price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },   
            ORDER_ITEMS(orders){  
                try { 
                    if(!orders){return null} 
                    let items = []
                    orders.forEach(element => {
                        let joined_items = element.joined_items
                        let payment_option_code = element.payment_option_code
                        if (joined_items) {
                            joined_items.forEach(item => {
                                items.push({
                                    ...item,
                                    payment_option_code:payment_option_code,
                                    account_type:element.account_type
                                }) 
                            }); 
                        }
                    }); 
                    return items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ORDER_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            RETAIL_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(element.selling_model)) ==  
                        this.MBS.actions.TEXT_SPACE0("retail price")
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'RETAIL_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            WHOLESALE_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(element.selling_model)) ==  
                        this.MBS.actions.TEXT_SPACE0("wholesale price")
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'WHOLESALE_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CASH_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return element.account_type == "cash"
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CASH_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            OTHER_OPTIONS_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return element.account_type == "payment_option"
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'OTHER_OPTIONS_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_ACCOUNT_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return element.account_type == "on_account"
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_ACCOUNT_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            TOTAL_SOLD(items){
                try {  
                    let total = 0
                    if (items) {
                        if(!items){return null}
                        items.forEach(element => {
                            let sold_price = this.toNumber(element.sold_price)
                            let quantity = this.toNumber(element.quantity)
                            let discount = this.toNumber(element.discount)
                            total = total+((sold_price-discount)*quantity)
                        }); 
                    }
                    return total
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TOTAL_SOLD',
                        page:PAGE_NAME, 
                    })
                }  
            },  
        },
        watch:{
            mbs_dialog(value){ 
            },  
            DayShift(value){ 
                this.EPSON_DAY_SHIFT_REQUEST({
                    report_type:this.report_type,
                    day_shift:this.DayShift,
                }) 
            },   
        } 
    }
</script>
